const hideElement = (elm) => {
  let adsJail = document.getElementById('ads-jail');
  let [iframe] = elm.getElementsByTagName('iframe');

  if (iframe) {
    // avoid load ads content
    iframe.src = ''
  }

  adsJail.appendChild(elm);

  console.log('~ hide:', elm);
}

const adsObserver = (elm) => {
  const config = { attributes: false, childList: true, subtree: true, characterData: true };

  const onChange = (mutationList, observer) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'childList') {
        let elm = mutation.target;

        // ignore self jail div
        if (elm.id === 'ads-jail') {
          continue;
        }

        // skip allowed ads in header
        if (elm.parentNode && elm.parentNode.id === 'viewAdSense') {
          continue;
        }

        if (elm.tagName.toLowerCase() === 'ins'
          || elm.classList.contains('adsbygoogle')
          // fucking dsgvo cookie banner
          || elm.classList.contains('google-revocation-link-placeholder')) {
          setTimeout(function () {
            hideElement(elm);
          }, 100);

          continue;
        }

        //console.log('+++ onChange:', elm, mutation);
      }
    }
  };

  const observer = new MutationObserver(onChange);
  observer.observe(elm, config);
}

const adsBlocker = function () {
  const htmlElm = document.lastChild;
  //const bodyElm = document.body;
  adsObserver(htmlElm);
}

export default adsBlocker;
