let NpcToning = {}

// clasic sepia
NpcToning.sepia1 = function (r, g, b) {
    var response = {}
    response.r = (r * 0.393) + (g * 0.769) + (b * 0.189);
    response.g = (r * 0.349) + (g * 0.686) + (b * 0.168);
    response.b = (r * 0.272) + (g * 0.534) + (b * 0.131);

    if (response.r > 255) {
        response.r = 255;
    }
    if (response.g > 255) {
        response.g = 255;
    }
    if (response.b > 255) {
        response.b = 255;
    }

    return response;
}

NpcToning.getRGB_test = function (r, g, b, toningType, toning) {
    var lum = (0.21 * r + 0.71 * g + 0.07 * b);
    r = g = b = lum;

    toning *= 5;

    var r1 = r * 0.4392 * 2;
    var g1 = g * 0.2588 * 2;
    var b1 = b * 0.0784 * 2;
    /*if(r1 < 0){
     r1 = 0;
     }
     if(g1 <= toning - 1){
     g1 = 0;
     }*/

    return {r: r1, g: g1, b: b1}
}

NpcToning.getRGB = function (r, g, b, toningType, toning) {
    //var lum = (0.299 * r + 0.587 * g + 0.114 * b); // color TV algorithm
    //var lum = (0.5 * r + 0.419 * g + 0.081 * b);
    var lum = (0.21 * r + 0.71 * g + 0.07 * b);

    if (toningType == 'grayScale') {
        return {r: lum, g: lum, b: lum}
    }

    /*var r1 = (r * 0.393) + (g * 0.769) + (b * 0.189);
     var g1 = (r * 0.349) + (g * 0.686) + (b * 0.168);
     var b1 = (r * 0.272) + (g * 0.534) + (b * 0.131);

     if(r1 > 255){
     r1 = 255;
     }
     if(g1 > 255){
     g1 = 255;
     }
     if(b1 > 255){
     b1 = 255;
     }*/

    var mx = NpcToning[toningType][toning];
    var r1 = ( lum > 255 - mx[0]) ? 255 : lum + mx[0];
    var g1 = ( lum < mx[1] ) ? 0 : lum + mx[1];
    var b1 = ( lum < mx[2] ) ? 0 : lum + mx[2];

    return {r: r1, g: g1, b: b1}
}

NpcToning.sepia = {
    1: [10, -2, -10],
    2: [19, -3, -19],
    3: [28, -5, -29],
    4: [37, -6, -39],
    5: [48, -8, -52],
    6: [58, -10, -65],
    7: [68, -12, -78]
}

NpcToning.cyanotype = {
    1: [-2, -1, 10],
    2: [-4, -2, 19],
    3: [-6, -3, 29],
    4: [-7, -4, 38],
    5: [-10, -5, 48],
    6: [-12, -6, 59],
    7: [-14, -7, 70]
}

NpcToning.red = {
    1: [14, -4, -6],
    2: [27, -7, -12],
    3: [40, -11, -18],
    4: [52, -15, -24],
    5: [66, -19, -31],
    6: [80, -23, -39],
    7: [93, -28, -47]
}

NpcToning.yellow = {
    1: [0, 0, 0],
    2: [0, 0, 0],
    3: [0, 0, 0],
    4: [0, 0, 0],
    5: [0, 0, 0],
    6: [0, 0, 0],
    7: [0, 0, 0]
}
NpcToning.green = {
    1: [0, 0, 0],
    2: [0, 0, 0],
    3: [0, 0, 0],
    4: [0, 0, 0],
    5: [0, 0, 0],
    6: [0, 0, 0],
    7: [0, 0, 0]
}

NpcToning.blueGreen = {
    1: [0, 0, 0],
    2: [0, 0, 0],
    3: [0, 0, 0],
    4: [0, 0, 0],
    5: [0, 0, 0],
    6: [0, 0, 0],
    7: [0, 0, 0]
}
NpcToning.blue = {
    1: [0, 0, 0],
    2: [0, 0, 0],
    3: [0, 0, 0],
    4: [0, 0, 0],
    5: [0, 0, 0],
    6: [0, 0, 0],
    7: [0, 0, 0]
}

NpcToning.purpleBlue = {
    1: [0, 0, 0],
    2: [0, 0, 0],
    3: [0, 0, 0],
    4: [0, 0, 0],
    5: [0, 0, 0],
    6: [0, 0, 0],
    7: [0, 0, 0]
}

NpcToning.redPurple = {
    1: [0, 0, 0],
    2: [0, 0, 0],
    3: [0, 0, 0],
    4: [0, 0, 0],
    5: [0, 0, 0],
    6: [0, 0, 0],
    7: [0, 0, 0]
}

export { NpcToning };