import Base64 from './Base64';

/**
 * Dmitrij Sosnovsenko
 * last update: 2014-01-31
 * + support send form
 * + reconnect by comet on response timeout
 */

/**
 * HTTP Request
 *
 * TODO: on timeout for xhttp and script make ABORT connect!
 *
 * References:
 * http://ru.wikipedia.org/wiki/XMLHttpRequest
 * http://javascript.ru/ajax/intro
 * http://javascript.ru/ajax/transport/iframe
 * http://javascript.ru/ajax/cross-domain-scripting
 * http://javascript.ru/forum/project/4203-krossbrauzernyjj-xmlhttprequest.html
 * http://msdn.microsoft.com/en-us/library/ms537505%28VS.85%29.aspx
 * http://xmlhttprequest.ru/
 * http://javascript.ru/blog/Ilya-Kantor/Zagruzka-dannyh-cherez-SCRIPT-s-otlovom-oshibok
 */

/**
 * type: json, script, iframe, (xml, text - XMLHttpRequest)
 *
 * Usage

 js.http({
		url: 'http://URL',
		user: 'user',		// default not set
		pass: 'password', 	// default not set
		method: 'get', 		// default get
		type: 'json', 		// default text
		data: {name: 'value'},
		timeout: 5000, 		// default 5000 ms
		onOpen: function(response){
			// this == js.http.obj[id]
			// TODO:
		},
		onTimeout: function(){
			// this == js.http.obj[id]
			// TODO:
		},
		onError: function(errorNum, errorText){
			// this == js.http.obj[id]
			// TODO:
		}
});
 */

/**
 type: 'json'; method: 'post', 'get'; server out => '{}' inner client callback function
 type: 'script'; method: only 'get'; crossdomain avilable; server out => native JS 'function_callback();'
 callback function must be as any get parameter

 type: 'form', 'iframe'; method: 'post', 'get'; server out => '<script>parent.function_callback();</script>'

 // long pulling, streaming
 type: 'comet'; method: 'post', 'get'; server out => '<script>parent.function_callback();</script>'
 */

js.http = function (options) {
    if (!options.data) {
        options.data = {}
    }

    if (this.elm && this.elm.nodeType == 1 && this.elm.tagName.toLowerCase() == 'form') {
        options.form = this.elm;
        options.type = 'iframe'; // form send to iframe
    }

    if (!options.url) {
        alert('js.http.ERROR: options.url is not valid');
        return false;
    }
    var id = js.uniqId();

    if (!js.http.obj) {
        js.http.obj = {}
    }
    var obj = js.http.obj[id] = {}
    obj.id = id;
    obj.opt = options;
    obj.response = null;
    obj.timeout = options.timeout ? options.timeout : 10000; // default 10 sec

    if (!options.download) {
        options.data['_http[type]'] = options.type;
    }
    options.data['_http[id]'] = id;
    if (options.url.indexOf('?') < 0) {
        options.url += '?';
    }
    else {
        options.url += '&'
    }
    obj.url = options.url + js.msec() + '&'; // no cache
    obj.user = options.user ? options.user : '';
    obj.pass = options.pass ? options.pass : '';
    obj.method = options.method ? options.method.toLowerCase() : 'get';

    var type = options.type;
    if (['json', 'xml', 'text'].indexOf(type) >= 0) {
        type = 'xhttp';
    } else if (!type) {
        type = 'iframe';
    }
    js.http.res[type].open(id); // type: xhttp, script, form, iframe, comet, sse
    return obj;
};

// ### RESOURCE ###
js.http.res = {}

js.http.res.isReady = (document.createElement('script').onreadystatechange !== undefined) ?
    function () { // IE, Opera
        var res = this.elm;
        //js('debug').addLast('<div>'+res.readyState + ' - ' + res.state+'</div>');
        if (res.readyState == 'complete' || res.readyState == 'loaded') {
            res.state = 1;
            return false;
        } else if (res.state > 0) {
            return false;
        } // error
    } :
    function () { // FF, Chrome, ..
        var res = this.elm;
        if (res.state > 0) {
            return false;
        }
    }

js.http.res.close = function (id) {
    js.obj.remove(id);
    delete js.http.obj[id].res;
    delete js.http.obj[id];
}

//### XMLHttp ###
js.http.res.xhttp = {}

js.http.res.xhttp.init = window.XMLHttpRequest ?
    function () {
        return new XMLHttpRequest()
    } :
    function () {
        var xhttp = ['Msxml2.XMLHTTP.6.0', 'Msxml2.XMLHTTP.3.0', 'Msxml2.XMLHTTP', 'Microsoft.XMLHTTP'];
        for (var i = 0, n = xhttp.length; i < n; i++) {
            try {
                return new ActiveXObject(xhttp[i])
            } catch (e) {
            }
        }
        return null;
    }

js.http.res.xhttp.open = function (id) {
    var obj = js.http.obj[id];
    var data = obj.opt.data;
    obj.res = js.http.res.xhttp.init();
    data = js.http.toQuery(data);
    if (obj.method == 'get') {
        obj.url += data;
        data = null;
    }
    obj.res.open(obj.method, obj.url, true, obj.user, obj.pass);
    if (obj.method == 'post') {
        obj.res.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded'); // must be only for POST
        obj.res.setRequestHeader("Content-length", data.length);
        obj.res.setRequestHeader("Connection", "close");
    }
    obj.res.send(data);
    //obj.res.abort= function(){}// native abort
    //obj.res.id= id; // IE6: set param is not possable, ActiveXObject is read only
    //js(obj.res).setTimer({
    js(obj).setTimer({
        step: {fn: js.http.res.xhttp.isReady, delay: 10, args: {}},
        final: {
            fn: function () {
            }, args: {}
        },
        timeout: {
            fn: function () {
                js.http.onTimeout.call(obj)
            }, delay: obj.timeout, args: {}
        }
    });
}

js.http.res.xhttp.isReady = function () {
    var obj = js.http.obj[this.id];
    var res = obj.res;
    if (res.readyState == 4) {
        if (res.status == 200) {
            setTimeout(function () {
                js.http.onOpen.call(obj)
            }, 0);
        } else {
            setTimeout(function () {
                js.http.onError.call(obj)
            }, 0);
        }
        return false; // cancel getResponse timer
    }
}

//### SCRIPT ###
js.http.res.script = {}

// TODO: IE6 bug readyState is permanent loading... and not complete
js.http.res.script.open = function (id) {
    var obj = js.http.obj[id];
    var data = obj.opt.data;
    obj.res = document.createElement('script');
    obj.res.id = id;
    obj.res.type = 'text/javascript';
    obj.res.async = true;
    obj.res.state = 0; // 0- not loaded; 1- ok; 2- error; 3- abort
    obj.res.src = obj.url + js.http.toQuery(data);
    obj._attemp = 0; // debug
    obj.res.onload = function () {
        obj.res.state = 1;
    }
    obj.res.onreadystatechange = null;

    obj.res.onerror = function () {
        obj.res.state = 2;
    }
    obj.res.abort = function () {
        obj.res.state = 3;
        obj.res.src = 'javascript:false;';
        //js(obj.res).remove();return; // TODO: abort connection, z.B. wenn parallel longpool läuft!!!
        js.http.res.script.close(obj.id);
    }

    js(obj.res).setTimer({
        step: {
            delay: 50, args: {},
            fn: function () {
                obj._attemp++;
                return js.http.res.isReady.call(this);
            }
        },
        final: {
            args: {},
            fn: function () {
                if (obj.state > 1) {
                    return false;
                } // error
                js.http.res.script.close.call(obj);
            }
        },
        timeout: {
            fn: function () {
                js.http.onTimeout.call(obj)
            }, delay: obj.timeout, args: {}
        }
    });
    // send
    if (obj.opt.onInit) {
        try {
            obj.opt.onInit.call(obj)
        } catch (e) {
        }
    }
    //js('head').addLast(obj.res);
    js('head').addFirst(obj.res); // circumvent an IE6 bug
}

js.http.res.script.close = function () {
    var obj = js.http.obj[this.id];
    obj.res.src = 'javascript:false;';
    js.dom.remove(obj.res);
    if (obj.opt.onClose) {
        try {
            obj.opt.onClose.call(obj)
        } catch (e) {
        }
    }
}

//### FORM ###
js.http.res.form = {}

/*js.http.res.form.init= function(id){
 return js.http.res.iframe.init.call(this, id);
 }*/

/*js.http.res.form.open= function(id){
 js.http.res.iframe.open.call(this, id);
 }*/

/*js.http.res.form.close= function(){
 js.http.res.iframe.close.call(this);
 }*/

//### IFRAME ###
js.http.res.iframe = {}

//js.http.res.form.init =
js.http.res.iframe.init = function (id) {
    var obj = js.http.obj[id];
    obj.res = js.element.create('iframe', {
        id: id,
        name: id,
        src: 'javascript:false;',
        style: {width: '1px', height: '1px', position: 'absolute', top: 0, visibility: 'hidden'}
    }, '');
    var tmp = js.http._createTmp();
    js(tmp).addLast(obj.res); // add upload iframe to temp container
    return obj;
}

//js.http.res.form.open =
js.http.res.iframe.open = function (id) {
    var obj = js.http.res.iframe.init(id);
    var data = obj.opt.data;
    obj._attemp = 0; // debug
    obj.res.onload = function () {
        obj.res.state = 1;
    }
    obj.res.onerror = function () {
        obj.res.state = 2;
    }
    obj.res.abort = function () {
        obj.res.state = 3;
        obj.res.src = 'javascript:false;';
        js.http.res.iframe.close(obj.id);
    }
    js(obj.res).setTimer({
        step: {
            fn: function (args) {
                obj._attemp++;
                return js.http.res.isReady.call(this);
            }, delay: 50, args: {}
        },
        timeout: {fn: js.http.onTimeout, delay: obj.timeout, args: {}},
        final: {
            args: {},
            fn: function () {
                if (obj.res.state > 1) {
                    return false;
                } // error
                js.http.res.iframe.close.call(obj);
            }
        }
    });//
    // send
    if (obj.opt.onInit) {
        try {
            obj.opt.onInit.call(obj)
        } catch (e) {
        }
    }
    js.http._sendPost(data, obj);
}

js.http.res.iframe.close = function () {
    var self = this;
    var obj = js.http.obj[self.id];
    setTimeout(function () {
        js.dom.remove(obj.res)
    }, 250);// fix Opera9
    if (obj.opt.onClose) {
        try {
            obj.opt.onClose.call(obj)
        } catch (e) {
        }
    }
}

//### COMET ####
//TODO: Opera Mobile: wartet auf PHP-Ende und nur dann macht weiter (aus eigenem buffer!)
js.http.comet = {};
js.http.res.comet = {}
js.http.res.comet.open = function (id) {
    var obj = js.http.res.iframe.init(id);
    obj.counter = 0; // how match call
    if (obj.opt.onInit) {
        try {
            obj.opt.onInit.call(obj)
        } catch (e) {
        }
    }
    var data = obj.opt.data;
    data['_http[state]'] = 'open';
    if (obj.method == 'get') {
        obj.res.src = obj.url + js.http.toQuery(data);
        return; // send get
    }
    js.http._sendPost(data, obj);
    js.http.comet.onTimeout(id); // response timeout
}

js.http.res.comet.close = function (id) {
    var obj = js.http.obj[id];
    if (!obj) {
        return false;
    }

    js.http.comet.clearTimeout(id); // response timeout

    obj.res.src = 'about:blank?' + js.msec(); // for IE need
    obj.res.src = 'javascript:false;';
    js(obj.res).remove();
    if (obj.opt.onClose) {
        try {
            obj.opt.onClose.call(obj)
        } catch (e) {
        }
    }
    js.obj.remove(obj.id);
    delete js.http.obj[id];
}

js.http.res.comet.reconnect = function (id) {
    var obj = js.http.obj[id];
    if (!obj) {
        return false;
    }
    var tmp = js.http._createTmp();
    var data = obj.opt.data;
    data['_http[state]'] = 'reconnect';
    js.http._sendPost(data, obj);
    js.http.comet.setTimeout(id); // response timeout
}

// TODO: ping from server to check connection by long response
js.http.res.comet._alive = function (id) {
    var obj = js.http.obj[id];
    obj._alive = 1;
}

js.http.comet._callback = function (id, response, fn) {
    var self = id ? js.http.obj[id] : this;
    if (!self) {
        return false;
    } // error
    var obj = js.http.obj[self.id];
    obj.response = response;
    if (response === undefined) {
        response = '';
    }
    if (obj.opt[fn]) {
        try {
            obj.opt[fn].call(obj, response)
        } catch (e) {
        }
    }
    // clear messages from server
    js.http._clearIFrame(obj.res);
}

// response timeout: wenn comet geöffnet ist, dann muss immer ein js.http.onMessage aufruf oder close sein
// deswegen setzen wir ein timout für die Warte auf einen response vom server,
// wenn innnerhalb timeout keine onMessage oder close war, dann rekonekten automatish.
js.http.comet.setTimeout = function (id) {
    var obj = js.http.obj[id];
    obj.tResponse = setTimeout(function () {
        var res;
        if (obj.opt.onTimeout) {
            try {
                res = obj.opt.onTimeout.call(obj)
            } catch (e) {
            }
        }
        if (res !== false) {
            js.http.res.comet.reconnect(obj.id);
        }
    }, obj.timeout);
}

js.http.comet.clearTimeout = function (id) {
    var obj = js.http.obj[id];
    clearTimeout(obj.tResponse);
}

//only for Socket
js.http.onReconnect = function (id, response) {
    js.http.comet._callback(id, response, 'onReconnect');
}

// only for Socket
js.onMsg = // short alias for oft callback from server
    js.http.onMessage = function (id, response) {
        var obj = js.http.obj[id];

        js.http.comet.clearTimeout(id); // response timeout: löschen timout event, weil connection da ist

        if (!obj.counter) {
            js.http.comet._callback(id, response, 'onOpen');
        }
        js.http.comet._callback(id, response, 'onMessage');
        js.http.comet.setTimeout(id); // response timeout: setzen neue timeout event bis nächste aufruf
        obj.counter++;
    }

// only for Socket: , some as onClose, but is 100% full completed
js.http.onSuccess = function (id, response) {
    js.http.comet.clearTimeout(id); // response timeout
    js.http.comet._callback(id, response, 'onSuccess');
    js.http.comet._callback(id, response, 'onClose');
}

// only for Socket
js.http.onClose = function (id, response) {
    js.http.comet._callback(id, response, 'onClose');
}

// Server-Sent Events
// http://habrahabr.ru/blogs/javascript/120429/
// http://www.whatwg.org/specs/web-apps/current-work/#dfnReturnLink-1
js.http.res.sse = {}
js.http.res.sse.open = function (id) {
    var obj = js.http.obj[id];
    var data = obj.opt.data;
    //var param= 'url='+Base64.encode(obj.url)+'&'+js.http.toQuery(data);
    var param = 'url=' + obj.url + '&' + js.http.toQuery(data);
    var query = Base64.encode(param);

    //var url= 'http://biodiscus.com/php/demo_http/sse.cgi';//obj.url+js.http.toQuery(data);
    //var url= 'http://biodiscus.com/framework/js/php/sse.cgi';//obj.url+js.http.toQuery(data);
    //var url= obj.url+js.http.toQuery(data);
    var url = 'http://biodiscus.com/framework/js/php/sse.cgi?' + query;

    obj.res = new EventSource(url);
    obj.res.onopen = function (e) {
        //if(obj.opt['onOpen']){try{obj.opt['onOpen'].call(obj, e.data)}catch(e){}}
    }
    obj.res.onmessage = function (e) {
        obj.response = e.data;
        if (obj.opt['onMessage']) {
            try {
                obj.opt['onMessage'].call(obj, e.data)
            } catch (e) {
            }
        }
    }
    obj.res.onclose = function (e) {
        // TODO: mom funktiniert nicht
        //alert('close');
        //if(obj.opt['onClose']){try{obj.opt['onClose'].call(obj, e.data)}catch(e){}}
    }
    obj.res.addEventListener('error', function (e) {
        if (e.eventPhase == EventSource.CLOSED) {
            // TODO: mom funktiniert nicht
            // connection closed
            //alert('connection closed');
        }
    }, false);

}

js.http.res.sse.close = function (id) {
    var obj = js.http.obj[id];
    //obj.res.close(); // ff error
    delete(obj.res);
}


//### COMMON ###
js.http.onOpen = function (id, response) {
    var self = id ? js.http.obj[id] : this;
    if (!self) {
        return false;
    } // error

    var obj = js.http.obj[self.id];
    if (response === undefined) {
        response = '';
    }


    if (obj.opt.type == 'comet') {
        obj.response = response;
        if (obj.opt.onOpen) {
            try {
                obj.opt.onOpen.call(obj)
            } catch (e) {
            }
        }// TODO: in php run js.http.onOpen
        js.http._clearIFrame(obj.res); // clear iframe
        return; // don't close connection
    } else if (obj.opt.type == 'script') {
    } else if (obj.opt.type == 'iframe') { // from server must be: echo '<script>parent.js.http.onOpen(\''.$_REQUEST['http']['connectId'].'\', '.$response.');</script>';
    } else if (obj.opt.type == 'form') {
    } else {
        if (!self.res.responseText && !self.res.responseXML) {
            js.http.onError.call(obj, -1, 'Response is empty')
            return;
        }
        if (obj.opt.type == 'json') {
            if (!self.res.responseText) {
                response = {};
            } else {
                response = eval('(' + self.res.responseText + ');'); // from server must be only: echo "{...}";
                //response= eval(self.responseText); // from server must be only: echo "{...}";
            }
        } else if (obj.opt.type == 'xml') {
            response = self.res.responseXML; // from server must be: echo "Content-Type: text/xml\n\n";
        } else {
            response = self.res.responseText; // from server must be: echo "Content-Type: text/html\n\n";
        }
    }
    obj.response = response;
    if (obj.opt.onOpen) {
        try {
            obj.opt.onOpen.call(obj)
        } catch (e) {
        }
    }
    //js.http.res.close(id);
}

js.http.onTimeout = function () {
    var self = this;
    var obj = js.http.obj[self.id];
    obj.res.abort();
    try {
        obj.opt.onTimeout.call(obj)
    } catch (e) {
    }
    ;
    js.http.res.close(obj.id);
}

js.http.onError = function (errNum, errText) {
    var self = this;
    var obj = js.http.obj[self.id];
    if (!errNum) {
        errNum = self.status
    }
    if (!errText) {
        errText = self.statusText
    }
    try {
        obj.opt.onerror.call(obj, errNum, errText)
    } catch (e) {
    }
    ;
    js.http.res.close(obj.id);
}

js.http.stopAll = window.stop ? function () {
    window.stop()
} : function () {
    window.document.execCommand('Stop')
}

// MISC

/**
 * JSON encode to query string
 */
js.http.toQuery = function (data) {
    var key, str = '';
    for (key in data) {
        if (str) {
            str += '&'
        }
        str += key + '=' + data[key];
    }
    return str;
}

// 2014-01-02 add send form
// add to form data params and remove it after send
js.http._sendPost = function (data, obj) {
    var hasForm = false;
    if (obj.opt.form) {
        hasForm = true;
    }

    var form = obj.opt.form || js.element.create('form', {}, '');
    form.target = obj.res.name;
    if (obj.url) {
        form.action = obj.url;
    }
    if (obj.method) {
        form.method = obj.method;
    }

    // add data to form
    var tmp;
    if (data) {
        if (hasForm) {
            tmp = js.dom.create('<div id="' + js.uniqId() + '" style="display:none"></div>', 'html');
        } else {
            tmp = form;
        }

        var key, val;
        for (key in data) {
            val = data[key] === undefined ? '' : data[key];
            var elm = js.dom.create('<input type="hidden" name="' + key + '" value="' + val + '">');
            js.dom.addLast(elm, tmp);
        }

        if (hasForm) {
            js.dom.addLast(tmp, form);
        } else {
            js.dom.addLast(form, js.http._createTmp());
        }
    }

    form.submit();

    // remove sended data
    if (tmp) {
        js(tmp).remove();
    }
}

// 2014-01-02 add form send support
// add to form data params and remove it after send.
js.http._sendForm = function (data, obj) {
    var form = obj.opt.form;
    if (!form) {
        return false;
    }

    form.target = obj.res.name;
    if (obj.url) {
        form.action = obj.url;
    }
    if (obj.method) {
        form.method = obj.method;
    }

    // add data to form
    var tmp;
    if (data) {
        tmp = js.dom.create('<div id="' + js.uniqId() + '" style="display:none"></div>', 'html');
        var key, val;
        for (key in data) {
            val = data[key] === undefined ? '' : data[key];
            var elm = js.dom.create('<input type="hidden" name="' + key + '" value="' + val + '">');
            js.dom.addLast(elm, tmp);
        }
        js.dom.addLast(tmp, form);
    }
    // send form
    form.submit();

    // remove custom data from form after data sending
    if (tmp) {
        js(tmp).remove();
    }

    return true;
}

js.http._createTmp = function () {
    if (!js.http._tmpId) {
        js.http._tmpId = 'tmp' + js.uniqId();
    }
    var id = js.http._tmpId;
    var elm = js(id).elm;
    if (!elm) {
        elm = js('<div id="' + id + '" style="display:none"></div>', 'html').elm;
        js('body').addLast(elm);
    }
    return elm;
}

// clear iFrame header from <script>...</script>
js.http._clearIFrame = function (elm) {
    var doc = js.iframeDoc(elm);
    var head = doc.getElementsByTagName('head')[0];
    var body = doc.getElementsByTagName('body')[0];
    while (head && head.firstChild) {
        js.dom.remove(head.firstChild)
    }
    if (body !== undefined) {
        while (body.firstChild) {
            js.dom.remove(body.firstChild)
        }
    }
}