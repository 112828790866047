import lut from './Lut';
import hsl from './Hsl';
import sharp from './Sharpen';
import point from './Point';
import Base64 from '../core/Base64';

// must be onLoad, if view source image is hide
js('imgPreview').onReady('onLoad', function () {
//js('imgPreview').onReady('onDisplay', function(){

    curve.init();
    curve.initPreviewImage();

    // beim start laden erste style
    var elmFileList = js('selFileList').elm;
    if (!elmFileList) {
        return false;
    }
    elmFileList.selectedIndex = 0;

    /**
     * chrom strict img.onload event
     * the img muss be allway new Image()
     */
    var src = this.elm.src;
    this.elm = new Image();

    js(this.elm).addEvent('load', function () {
        npc.load();
        js('viewSourceImage').drag({});
        js('viewHelp').drag({});
    }, false);
    this.elm.src = src;

    //curve.loadPreviewImage();
});

var curve = {
    tUpdate: null, // timer to update change only once during 1 sec.
    wasChanged: false,
    lutCurve: null // calced lut
}

curve.init = function () {
    curve.initCanvas('cLutImage');

    curve.initCanvas('cCurve');
    curve.initCanvas('cCurve2'); // org lut

    curve.initCanvas('cGrid');
    curve.cCurve.canvas.lineJoin = 'round';
    curve.cCurve.canvas.lineWidth = 1;
    curve.cCurve.canvas.strokeStyle = '#ff0000';

    curve.initCanvas('cHistImg');
    curve.cHistImg.canvas.lineWidth = 1;
    curve.cHistImg.canvas.lineCap = 'butt'
    curve.cHistImg.canvas.strokeStyle = '#000000';

    curve.initCanvas('cHistLut');
    curve.initShowCurveHist();

    curve.initClearCanvas('cCurve');
    curve.initClearCanvas('cCurve2');
    curve.initClearCanvas('cHistImg');
    curve.initClearCanvas('cHistLut');

    curve.drawCurveGrid();
}

curve.initCanvas = function (id) {
    var elm = js(id).elm;

    if (!elm || !elm.getContext) {
        return false;
    }
    curve[id] = {
        elm: elm,
        canvas: elm.getContext("2d")
    };

    //curve[id].canvas.save();
    return true;
}

curve.initHist = function () {
    curve.hist = {img: [], lut: []};
    var i;
    for (i = 0; i < 256; i++) {
        curve.hist.img[i] = 0;
        curve.hist.lut[i] = 0;
    }
}

curve.drawLutImage = function (lut) {
    var img = js('imgPreview').elm;
    var c = curve.cLutImage;
    c.elm.width = img.width;
    c.elm.height = img.height;
    c.canvas.drawImage(img, 0, 0);
    var imgData = c.canvas.getImageData(0, 0, img.width, img.height);
    curve.initHist();

    // monochrome
    var isMonochrome = npc.data.picCtrl == 'Monochrome' ? 1 : 0;
    var toningType = 'grayScale',
      toning,
      toningTypeList;

    if (isMonochrome) {
        toningTypeList = {
            80: '',
            81: 'sepia',
            82: 'cyanotype',
            83: 'red',
            84: 'yellow',
            85: 'green',
            86: 'blueGreen',
            87: 'blue',
            88: 'purpleBlue',
            89: 'redPurple'
        }
        if (toningTypeList[npc.data.toningType]) {
            toningType = toningTypeList[npc.data.toningType];
        }
    }

    var i;
    var r, g, b;
    var Y; // brightness
    var size = imgData.data.length;
    var data = imgData.data;
    for (i = 0; i < size; i += 4) {
        r = imgData.data[i];
        g = imgData.data[i + 1];
        b = imgData.data[i + 2];

        Y = curve.getBrightness(r, g, b);
        curve.hist.img[Y]++;

        r = lut[r];
        g = lut[g];
        b = lut[b];

        if (isMonochrome) {
            var res = npc.toning.getRGB(r, g, b, toningType, npc.data.toning);
            r = res.r;
            g = res.g;
            b = res.b;
        }

        Y = curve.getBrightness(r, g, b);
        curve.hist.lut[Y]++;

        imgData.data[i] = r;
        imgData.data[i + 1] = g;
        imgData.data[i + 2] = b;
    }

    c.canvas.putImageData(imgData, 0, 0);
}

curve.setAdjust = function () {
    if (curve.tUpdate) {
        clearTimeout(curve.tUpdate);
    }

    curve.tUpdate = setTimeout(function () {
        var img = js('imgPreview').elm;
        var c = curve.cLutImage;
        //c.canvas.drawImage(img, 0, 0);
        var imgData = c.canvas.getImageData(0, 0, img.width, img.height);

        curve.setSharp(img, imgData);
        if (npc.data.customCurveFlag) {
            curve.setContrast();
            curve.setBrightness();
        }
        curve.setHSL(img, imgData);

        c.canvas.putImageData(imgData, 0, 0);
        curve.wasChanged = false;
    }, 75);

}

// TODO: sharp from org imgData
curve.setSharp = function (img, imgData) {
    var sharping = npc.data.sharpening;
    if (sharping > 0) {
        sharping--;
        sharping = (sharping * 0.4 / 9 + 0.02).toFixed(2);

        sharp(sharping, imgData.data, img.width, img.height);
        //imgData.data = sharp(sharping, imgData.data, img.width, img.height);
    }
}

curve.setContrast = function () {
}
curve.setBrightness = function () {
}

curve.setHSL = function (img, imgData) {
    var satValues = {'-3': -45, '-2': -30, '-1': -15, '0': 0, '1': 15, '2': 30, '3': 45};
    var hueValues = {'-3': -12, '-2': -8, '-1': -4, '0': 0, '1': 4, '2': 8, '3': 12};

    var params = {
        saturation: satValues[npc.data.saturation] || 0,
        hue: hueValues[npc.data.hue] || 0
    }

    hsl(params, imgData.data, img.width, img.height);
}


curve.set = function () {
}

curve.draw = function (data) {

    // js - curve cals
    lut.init(data);
    curve.lutCurve = lut.getLUTDataByPoints();

    //curve.lutCurve = npc.data.lut.lut;
    var lutCurve = curve.lutCurve;

    var x, y, y2;
    var len = data.lut.curve.length || 0;
    //$('#debug').text(lutCurve.curve);
    //curve.drawLutImage(data.lut.curve)
    curve.drawLutImage(lutCurve.curve);


    var maxHistImg = curve.getArrayMax(curve.hist.img);
    var maxHistLut = curve.getArrayMax(curve.hist.lut);
    var kHistImg = Math.ceil(maxHistImg / 256);
    var kHistLut = Math.ceil(maxHistLut / 256);

    var cCurve = curve.cCurve.canvas;
    var cCurve2 = curve.cCurve2.canvas;
    var cHistImg = curve.cHistImg.canvas;
    var cHistLut = curve.cHistLut.canvas;

    curve.clearCanvas('cCurve');
    curve.clearCanvas('cCurve2');
    var size = curve.getCanvasSize('cHistLut');
    //curve.cHistLut.canvas.clearRect(0, 0, size.w, size.h);
    curve.clearCanvas('cHistLut');

    cHistImg.beginPath();
    cCurve.beginPath();
    cCurve2.beginPath();


    if (!len) {
        cCurve.moveTo(0, 256);
        cCurve.lineTo(256, 0);
    }

    // test points
    /*cCurve.fillRect(0, 0, 1, 1);
     cCurve.fillRect(0, 255, 1, 1);
     cCurve.fillRect(255, 0, 1, 1);
     cCurve.fillRect(255, 255, 1, 1);*/

    //cCurve.moveTo(0, 256);
    //cCurve.lineTo(256, 0);

    //cCurve.moveTo(0, 1);
    //cCurve.lineTo(256, 1);

    // org lut curve
    var orgLutCurve = data.lutSource.split(' ');
    var kOut = 32767 / 255;

    cCurve2.strokeStyle = '#999';
    cCurve.strokeStyle = '#FF0000';
    cCurve.fillStyle = '#FF0000';
    for (x = 0; x < len; x++) {
        // original image histogram
        y = 256 - Math.ceil(curve.hist.img[x] / kHistImg);
        if (y < 0) {
            y = 0;
        }
        cHistImg.fillStyle = '#008800';
        cHistImg.fillRect(x, y, 1, 256);

        // custom curve histogram
        y = 256 - Math.ceil(curve.hist.lut[x] / kHistLut);
        if (y < 0) {
            y = 0;
        }
        cHistLut.fillStyle = '#bbb';
        cHistLut.fillRect(x, y, 1, 256);

        // curve
        /*y = 255 - data.lut.curve[x];
         y2 = 255 - orgLutCurve[x]/kOut;*/

        y = 255 - lutCurve.curve[x];
        y2 = 255 - data.lut.curve[x];

        if (!x) {
            cCurve.moveTo(0, y);
            cCurve2.moveTo(0, y2);
        } else {
            cCurve.lineTo(x + 0, y);
            cCurve.moveTo(x + 0, y);

            cCurve2.lineTo(x + 0, y2);
            cCurve2.moveTo(x + 0, y2);
        }
    }

    cCurve.stroke();
    cCurve2.stroke();

    //cHistImg.stroke();
    //cHistLut.stroke();
    curve.showCurveHist();

    curve.drawCurvePoints(data);
}

curve.getArrayMax = function (arr) {
    var len = arr.length;
    var i;
    var max = 0;
    for (i = 0; i < len; i++) {
        max = Math.max(max, arr[i]);
    }

    return max;
}

curve.getBrightness = function (r, g, b) {
    return Math.ceil(0.3 * r + 0.59 * g + 0.11 * b);
}

curve.getCanvasSize = function (name) {//alert(name);
    var h = curve[name].elm.height;
    var w = curve[name].elm.width;

    return {w: w, h: h}
}

curve.drawCurveGrid = function () {
    var cGrid = curve.cGrid.canvas;
    var size = curve.getCanvasSize('cGrid');
    var numGrid = 4;

    var xStep = Math.ceil(size.w / numGrid);
    var yStep = Math.ceil(size.h / numGrid);
    var i;
    var x, y;
    cGrid.fillStyle = '#888888';
    for (i = 1; i < numGrid; i++) {
        x = i * xStep;
        y = i * yStep;
        cGrid.fillRect(x, 0, 1, size.h);
        cGrid.fillRect(0, y, size.w, 1);
    }
}

curve.drawCurvePoints = function (data) {
    var points = data.splain.lutPoints; // 100% Ok!, die points sind für curve skaliert (bei min/max)
    var useGamma = (lut.$gamma != 1) ? 1 : 0;
    var cCurve = curve.cCurve.canvas;
    var size = curve.getCanvasSize('cCurve');
    var amount = points.length;
    var i, x, y, pointXY;

    cCurve.beginPath();
    cCurve.save();
    cCurve.strokeStyle = '#444';
    cCurve.fillStyle = '#222';

    for (i = 0; i < amount; i++) {
        x = Math.round(points[i][0]);
        y = curve.lutCurve.curve[x];
        y = 255 - y;

        // fix select point position
        if (js('selectedPoint_' + (i + 1)).elm) {
            js('selectedPoint_' + (i + 1)).css({
                left: (x - point._selOffsetX) + 'px',
                top: (y - point._selOffsetY) + 'px'
            });
        }

        cCurve.moveTo(x, y);
        cCurve.arc(x, y, 2, 0, 2 * Math.PI);
    }
    cCurve.closePath();
    cCurve.fill();
    cCurve.stroke();
    cCurve.restore();
}

/**
 * depredicate
 * TODO: prüfen, und wenn nicht benutzt, dann löschen
 *
 * @type Object
 */
curve.checkPoint = function (x, y) {
    if (x < lut.$minIn) {
        x = lut.$minIn;
    }
    if (x > lut.$maxIn) {
        x = lut.$maxIn;
    }

    return {x: x, y: y}
}

curve.initClearCanvas = function (name) {
    var size = curve.getCanvasSize(name);
    curve[name].clearData = curve[name].canvas.createImageData(size.w, size.h);
    /*var i;
     var len = curve[name].clearData.data.length;
     for (i = 0; i < len; i += 4) {
     curve[name].clearData.data[i+0] = 255;
     curve[name].clearData.data[i+1] = 255;
     curve[name].clearData.data[i+2] = 255;
     curve[name].clearData.data[i+3] = 255;
     }*/
}

curve.clearCanvas = function (name) {//alert(curve[name].clearData);
    curve[name].canvas.putImageData(curve[name].clearData, 0, 0);
}

curve.initShowCurveHist = function () {
    js('showCurveHist').addEvent('click', curve.showCurveHist);
}

curve.showCurveHist = function () {
    if (js('showCurveHist').elm.checked) {
        curve.cHistImg.elm.style.visibility = 'hidden';
        curve.cHistLut.elm.style.visibility = 'visible';
    } else {
        curve.cHistImg.elm.style.visibility = 'visible';
        curve.cHistLut.elm.style.visibility = 'hidden';
    }
}

curve.initPreviewImage = function () {
    js('btnUpdateImage').addEvent('click', function () {
        curve.loadPreviewImage()
    });
}

curve.loadPreviewImage = function (resolution) {
    var url = js('inpImageUrl').elm.value;
    url = Base64.encode(url);
    resolution = resolution || js('previewRes').elm.value;
    var src = '?cmd=image&s=' + url + '&res=' + resolution + '&' + js.uniqId();

    var oldPreview = js('imgPreview');
    oldPreview.css({'display': 'none'});
    var elm = js.dom.create('<img src="" class="img-preview" />')
    oldPreview.addAfter(elm);
    js('imgPreview').remove();
    elm.id = 'imgPreview';

    elm.onload = function () {
        /*point.updateValues(1); // update curve
         curve.draw(npc.cache[npc.cacheKey]);
         curve.setAdjust();*/

        if (!resolution) {
            npc.load(); // load npc on load of image
        } else {
            point.updateValues(1); // update curve
            curve.setAdjust(); // update adjusts
        }
    }
    elm.src = src;
}


curve.initNewPoint = function () {
    js('cCurve').addEvent('mousedown', function (e) {
        var pos = $('#cCurve').offset();
        var x = e.posX - pos.left - 1;
        var y = e.posY - pos.top - 1;
        y = 256 - y;

        var points = npc.data.splain.points;
        var gamma = npc.data.splain.gamma;
        var amount = points.length;
        var px, py, i;
        var area = 3;
        var num = 0;

        for (i = 0; i < amount; i++) {
            px = Math.round(points[i][0]);
            py = Math.round(points[i][1]);
            if (gamma != 1) {
                py = data.lut.curve[px];
            }
            //py = 256 - py;

            //$('#debug').append(y +' : ' + py + '; ');

            if (x >= px - area && x <= px + area && y >= py - area && y <= py + area) {
                num = i + 1;
                break;
            }
        }

        if (num > 0) {
            point.select(num)
        }

    });
}

export default curve;
