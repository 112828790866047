var lut = {}

lut.init = function ($data) {
    var $data = $data.splain;
    //var $points = $data.lutPoints;
    var $points = $data.points;

    //lut.$kIn = 256 / 255;
    //lut.$kOut = 32767 / 255;

    lut.$numPoints = $points.length - 1;
    lut.$gamma = 1 / $data.gamma;

    //#########
    var $minIn = parseInt($data['minInput'], 10);
    var $maxIn = parseInt($data['maxInput'], 10);
    var $minOut = parseInt($data['minOutput'], 10);
    var $maxOut = parseInt($data['maxOutput'], 10);

    var $diffIn = ($maxIn - $minIn) / 255;
    var $diffOut = ($maxOut - $minOut) / 255;
    var $in, $out;
    //#########

    lut.$points = [];
    var $i;
    for ($i = 0; $i <= lut.$numPoints; $i++) {

        $in = parseInt($points[$i][0], 10);
        $out = parseInt($points[$i][1], 10);

        $in = $in * $diffIn + $minIn;
        $out = $out * $diffOut + $minOut;

        //$in *= lut.$kIn;
        //$out *= lut.$kOut;

        lut.$points[$i] = [
            $in,
            $out
        ];
    }

    // scale max/min
    lut.$minIn = lut.$points[0][0];
    lut.$maxIn = lut.$points[lut.$numPoints][0];
    lut.$minOut = $data['minOutput'];
    lut.$maxOut = $data['maxOutput'];
    //lut.$minOut = $data['minOutput'] * lut.$kOut;
    //lut.$maxOut = $data['maxOutput'] * lut.$kOut;

    // size of diff input and output is for gamma calc
    lut.$diffIn = lut.$maxIn - lut.$minIn;
    lut.$diffOut = lut.$maxOut - lut.$minOut;
}

lut.arraySize = function (arr) {
    var i;
    var len = arr.length;
    var size = 0;
    for (i = 0; i < len; i++) {
        if (arr[i] != undefined) {
            size++;
        }
    }

    return size;
}

lut.getLUTDataByPoints = function () {
    var $points = lut.$points;
    var $numPoints = lut.$numPoints;
    var $rs = [];
    var $M = [];

    var $i, $j, $M3;
    var $x0, $x1, $x2, $y0, $y1, $y2;

    for ($i = 1; $i < $numPoints; $i++) {
        $x0 = $points[$i - 1][0];
        $x1 = $points[$i][0];
        $x2 = $points[$i + 1][0];

        $y0 = $points[$i - 1][1];
        $y1 = $points[$i][1];
        $y2 = $points[$i + 1][1];

        $rs[$i] = 3 * ( ($y2 - $y1) / ($x2 - $x1) - ($y1 - $y0) / ($x1 - $x0) );

        $M[$i] = [];
        $M[$i][$i - 1] = $x1 - $x0;
        $M[$i][$i] = ($x2 - $x0) * 2;
        $M[$i][$i + 1] = $x2 - $x1;
    }

    var $A = lut.LU($M);
    var $sizeA = lut.arraySize($A);

    var $Z = [];
    $Z[1] = $rs[1];
    for ($i = 2; $i <= $sizeA; $i++) {
        $Z[$i] = $rs[$i] - $A[$i][$i - 1] * $Z[$i - 1];
    }

// TODO: by 65:65 - $Z ist leer=> debug hier
    var $B = [];
    var $sizeZ = lut.arraySize($Z);
    if ($sizeA > 0) {
        $B[$sizeA] = $Z[$sizeZ] / $A[$sizeA][$sizeA];
    } else {
        $B[$sizeA] = 0;
    }

    var $tmp;
    for ($i = $sizeA - 1; $i >= 1; $i--) {
        $tmp = $Z[$i];
        for ($j = $i + 1; $j <= $sizeA; $j++) {
            if ($A[$i][$j] !== undefined) {
                $tmp -= $A[$i][$j] * $B[$j];
            }
        }
        $B[$i] = $tmp / $A[$i][$i];
    }
    $B[0] = 0;
    $B[$sizeA + 1] = 0;

    var $C = [];
    for ($i = 0; $i <= $sizeA; $i++) {
        $C[$i] =
            ($points[$i + 1][1] - $points[$i][1])
            / ($points[$i + 1][0] - $points[$i][0])
            - ($B[$i + 1] - $B[$i]) * ($points[$i + 1][0]
            - $points[$i][0]) / 3
            - $B[$i] * ($points[$i + 1][0] - $points[$i][0]);
    }

    var $AA = [];
    for ($i = 0; $i <= $sizeA; $i++) {
        $AA[$i] = ($B[$i + 1] - $B[$i]) / (3 * ($points[$i + 1][0] - $points[$i][0]) );
    }

    var $response = {'lut': [], 'curve': []};
    var $in = 0;
    var $out, $d;
    var $x1, $x2, $yy;
    for ($i = 0; $i < $numPoints; $i++) {
        $x1 = $points[$i][0];
        $x2 = $points[$i + 1][0];
        $yy = $points[$i][1];

        for ($j = $x1; $j < $x2; $j++) {
            $j = $in;
            $d = $j - $x1;

            $out =
                $AA[$i] * Math.pow($d, 3)
                + $B[$i] * Math.pow($d, 2)
                + $C[$i] * $d
                + $yy;

            if (lut.$gamma != 1 && $in > $points[0][0]) {
                //$out = lut.curveGamma($in, $out);
                $out += lut.gamma($in);
            }

            if ($out < lut.$minOut) {
                $out = lut.$minOut;
            } else if ($out > lut.$maxOut) {
                $out = lut.$maxOut;
            }

            if ($in < $points[0][0] && $out < $points[0][1]) {
                $out = $points[0][1];
            }

            $response['lut'][$in] = Math.round($out);
            $response['curve'][$in] = Math.round($out);
            //$response['curve'][$in] = Math.round($out / lut.$kOut);

            $in++;
        }
    }

    // afte last point to 256
    var $inLast;
    for ($inLast = $in; $inLast < 257; $inLast++) {
        $out = $points[$numPoints][1];
        $response['lut'][$inLast] = Math.round($out);
        $response['curve'][$inLast] = Math.round($out);
        //$response['curve'][$inLast] = Math.round($out / lut.$kOut);
    }

    return $response;
}

/*lut.curveGamma = function($in, $out) {
 var $ratioIn = ($in - lut.$minIn) / lut.$diffIn;
 $out +=  (Math.pow($ratioIn, lut.$gamma) - $ratioIn) * lut.$diffOut;

 return $out;
 }*/

lut.gamma = function ($in) {
    var $ratioIn = ($in - lut.$minIn) / lut.$diffIn;

    return (Math.pow($ratioIn, lut.$gamma) - $ratioIn) * lut.$diffOut;
}


lut.LU = function ($A) {
    var $sizeA = lut.arraySize($A);
    var $i, $j, $k;

    for ($i = 1; $i <= $sizeA; $i++) {

        for ($j = $i; $j <= $sizeA; $j++) {
            for ($k = 1; $k < $i; $k++) {
                if ($A[$k] && $A[$k][$j] !== undefined) {
                    $A[$i][$j] -= $A[$i][$k] * $A[$k][$j];
                }
            }
        }

        for ($j = $i + 1; $j <= $sizeA; $j++) {
            for ($k = 1; $k < $i; $k++) {
                if ($A[$j] && $A[$j][$k] !== undefined) {
                    $A[$j][$i] -= $A[$j][$k] * $A[$k][$i];
                }
            }
            if ($A[$j] && $A[$j][$i] !== undefined) {
                $A[$j][$i] /= $A[$i][$i];
            }
        }
    }

    return $A;
}

export default lut;