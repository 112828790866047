/*
 * Dimi JS Base Framework: js
 * Dmitrij Sosnovsenko
 * biodiscus@web.de
 *
 * last update: 2013-06-30
 * bugfix: onReady, if loaded core in head
 */

// references:
// http://www.spravkaweb.ru/css/reference/attributes/border_and_layout/boxsizing
// http://usabili.ru/news/2009/07/01/box_model.html
// http://habrahabr.ru/blogs/jquery/69917/
// TODO: STADY - http://www.codeproject.com/KB/scripting/leakpatterns.aspx

var
	//window= this,
	js= window.js= function(sel,type){
		// TODO: profile it for 10.000
		/*if(arguments.length && !sel){
			return null;
		}
		if(!arguments.length){
			return new js.core.init(document);
		}*/
		return new js.core.init(sel,type);
	};

js.ver= '0.11';

js.core= js.prototype= {
	type: {'obj':1, 'html':2, 'tag':3, 'name':4},

	isTag: {'body':1,'head':1},

	init: function(sel,type){
		// html element id -> js('id')
		if(typeof sel == 'string' ){
			// if element is in the collection, than return this+
			var obj= js.obj[sel];// quickly
			if(obj && obj.elm){
				this.id= sel;
				this.elm= obj.elm;
				return this;
			}
			if(!type){
				if(js.core.isTag[sel]){this.elm= document.getElementsByTagName(sel)[0]}
				else{this.elm= document.getElementById(sel) || document.querySelector(sel);}
			}else{
				if(type == 'name'){
					this.elm= document.getElementsByName(sel);
				}else
				if(type == 'tag'){
					this.elm= document.getElementsByTagName(sel);
				}
				else
				if(type == 'html'){
					var tmp= document.createElement('div');
					tmp.innerHTML= sel;
					this.elm= tmp.firstChild;
					this.id= js.obj.add(this.elm.id,this.elm);
					return this;
				}else
				if(type == 'obj'){ // not DOM object, simple for timer js(js.uniqId(),'obj').setTimer(); TODO: testen
					this.elm= null;
					this.id= js.obj.add(sel.id,{});
					return this;
				}
			}

			this.id= js.obj.add(sel,this.elm);
			return this;
		}else
		// DOM element
		if(sel && sel.nodeType){
			this.elm= sel;
			if(sel == document){
				this.id= this.elm.id= 'document';
				js.obj.add('document',sel);
				return this;
			}
			this.id= js.obj.add(sel.id,sel);
			return this;
		}else
		if(sel == window){
			this.elm= sel;
			return this;
		}else
		if(js.isObject(sel)){
			this.elm= sel;
			this.id= js.obj.add(sel.id,sel);
			return this;
		}
		else{
			if(sel && sel.constructor){
				//alert((typeof sel)+' : '+js.isObject(sel));
			}
			return js(document);
		}
	}// init

	/* reserved sel:{
		id: function(sel){return document.getElementById(sel)},
		name: function(sel){return document.getElementsByName(sel)},
		tag: function(sel){return document.getElementsByTagName(sel)},
		body: function(){return document.getElementsByTagName("body")[0]},
		html: function(sel){return js.dom.create(sel)}
	}*/

};// js.core

//link from js() to all function in js
js.core.init.prototype = js;
js.is= true; // for check, it is "js"-object

js.browser= (function(){
	var is= function(str){return (navigator.userAgent.indexOf(str) != -1)};
	return {
		check: is,
		ie: is('MSIE'),
		opera: is('Opera'),
		ff: is('Firefox'),
		chrome: is('Chrome'),
		safari: is('Safari')&& !is('Chrome'),
		konqueror: is('konqueror'),

		webkit: is('WebKit'), // Safari
		khtml: is('KHTML'),
		mozilla: is('Mozilla'),
		gecko: is('Gecko')
	}
})();


// Collection of all objects in JS. Jeder Object kennt alle andere im JS und kann die Ã¤ndern!
// js.collection= [];//[{elm:null,timerId:null}];
// js.obj - alias zum js.collection
js.collection=
js.obj= {
	add: function(id,elm){
		var obj= js.obj[id];
		if(obj){
			if(obj.elm){return id}
			obj.elm= elm;
			return id;
		}
		if(!id){
			elm.id= id= 'id'+js.uniqId();
		}
		js.obj[id]= {id:id,elm:elm,timerId:null};
		return id;
	},

	remove: function(id){if(js.obj[id]){delete js.obj[id]}},

	// swap two object, for event copy use js.event.copy
	swap: function(elm1,elm2){
		var el1= js(elm1).elm;
		var el2= js(elm2).elm;
		var id1= el1.id;
		var id2= el2.id;
		js.obj[id1].elm= el2;
		js.obj[id2].elm= el1;
		js.obj[id1].elm.id= id1;
		js.obj[id2].elm.id= id2;
	},

	getTimer: function(id){return js.obj[id].timerId},

	setTimer: function(id,callback,delay){
		var res;
		var obj= js.obj[id];
		obj.stop=0;
		delay= delay || 75;

		function _stop(id){
			var obj= this;
			js.obj.clearTimer(id);
			if(typeof obj.final == 'function'){
				try{obj.final.call(obj,obj.final.args);}catch(e){}
			}
		};

		function _loop(){
			if(obj.stop){_stop.call(obj,id);return}
			if(obj.pause){return}
			if(callback.call(obj,obj.step.args) === false){_stop.call(obj,id)}
		};
		_loop();
		obj.timerId= setInterval(_loop,delay);
	},

	clearTimer: function(id){
		var obj= js.obj[id];
		clearInterval(obj.timerId);
		clearTimeout(obj.timeoutId);
		obj.timerId= null;
		obj.timeoutId= null;
		obj.stop=0;
		obj.pause=0;
		obj.timeout=0;
	}
};

js.initTimer= function(){
	js.obj[this.id].stop=0;
	js.obj[this.id].timeout=0;
};


/**
 * Set timer for object
 * @param object opt - options:
{
	step: {fn: function(args){}, delay: 10, args: {}},
	final: {fn: function(args){}, args: {}},
	timeout: {fn: function(args){}, delay: 5000, args: {}}
}

step => final
timeout => #

TODO: args, testen

*/
js.setTimer= function(opt){
	var id= this.id;
	var obj= js.obj[id];
	if(!opt.step || !opt.step.fn){return};
	obj.step= {args: opt.step.args};
	js.obj.setTimer(id,opt.step.fn,opt.step.delay||50);

	if(opt.timeout && (typeof opt.timeout.fn == 'function') && opt.timeout.delay > 0){
		obj.timeoutId= setTimeout(function(){
			js.obj.clearTimer(id);
			try{opt.timeout.fn.call(obj,opt.timeout.args)}catch(e){}
		},opt.timeout.delay);
	}
	if(!opt.final || (opt.final && (typeof opt.final.fn != 'function'))){return this}
	obj.final= opt.final.fn;
	obj.final.args= opt.final.args;

	return this;
};

js.stopTimer= function(){
	js.obj[this.id].stop=1;
};

js.pauseTimer= function(){
	js.obj[this.id].pause=1;
};

js.continueTimer= function(){
	js.obj[this.id].pause=0;
};

js.clearTimer= function(){
	js.obj.clearTimer(this.id);
};

/**
* Timer
*/

js.timer= function(opt){
	var id= this.id;
	var obj= js.obj[id];
	obj.timer= opt;

	if(opt.autoStart || opt.autoStart === undefined){
		js.timer.start();
	}
};

js.timer.start= function(id){
	var obj= js.obj[id];
	var timer= obj.timer;
	if(timer.timerId){return}

	var onStep= timer.onStep.fn;
	var args= timer.onStep.args;
	timer.stop=0;
	var delay= timer.onStep.delay || 75;

	function stop(id, callback){
		var obj= this;
		js.timer.clear(id);
		js.timer._callback(id, callback);
	};

	function loop(){
		if(timer.stop){stop.call(obj, id, 'onStop');return}
		if(timer.pause){return}
		if(onStep.call(obj, args) === false){stop.call(obj, id, 'onFinish')}
	};

	js.timer._callback(id, 'onStart');
	loop();
	timer.timerId= setInterval(loop, delay);
};

js.timer._callback= function(id, callback){
	var obj= js.obj[id];
	callback= obj.timer[callback];
	var delay= callback.delay || 0;
	if(typeof callback.fn != 'function'){return false}
	if(delay){
		setTimeout(function(){
			try{return (function(){callback.fn.call(obj, callback.args)})() }catch(e){}
		}, delay);
	}else{
		try{return callback.fn.call(obj, callback.args)}catch(e){}
	}
};

js.timer.clear= function(id){
	var obj= js.obj[id].timer;
	clearInterval(obj.timerId);
	clearTimeout(obj.timeoutId);
	obj.timerId= null;
	obj.timeoutId= null;
	obj.stop=0;
	obj.pause=0;
	obj.timeout=0;
}

js.timer.stop= function(id){
	js.obj[id].timer.stop=1;
}

js.timer.pause= function(id){
	if(js.obj[id].timer.pause){return}
	js.obj[id].timer.pause=1;
	js.timer._callback(id, 'onPause');
}

js.timer.resume= function(id){
	if(!js.obj[id].timer.pause){return}
	js.obj[id].timer.pause=0;
	js.timer._callback(id, 'onResume');
}


/**
 * usefull ist for: array, object
 * return undefined, array, boolean, string, number, object, function
 */
js.typeOf= function(o){
	if(typeof o == 'object' && o.pop){return 'array'}
	return typeof o;
}

// All is-function work without object quickly as with object
// Note: if(!js.isObject(obj)){} is quickly as if(js.isArray(arr)) in 2 times!
js.isDefined= function(o){return o !== undefined}
js.isBool= function(o){return typeof o == 'boolean'}
js.isOBool= function(o){return (o instanceof Boolean)} // o= new Boolean; typeof o == object
//js.isOBool= function(o){return (o && (o.constructor === Boolean))} // slow // o= new Boolean; typeof o == object
js.isArray= function(o){return (typeof o == 'object' && o.pop)} // == [] == (new Array)
//js.isArray1= function(o){return o instanceof Array} // == [] == (new Array)
js.isObject= function(o){return (typeof o == 'object' && !o.pop)}   // quickly // == {} == (new Object) == (new function(){}) == (new Date) == (new String) == (new XLMHtpReques).. != [] != 1 ..
js.isOObject= function(o){return (o && (o.constructor === Object))} // slow    // == {} == (new Object) != (new function(){}) != (new Date) != (new String) != (new XLMHtpReques).. != [] != 1 ..
// Native typeof
js.isString= function(o){return typeof o == 'string'}
js.isNumber= function(o){return typeof o == 'number'}
js.isFunction= function(o){return typeof o == 'function'}

js.formatOf= function(){
	var val= arguments.length ? arguments[0] : this;
	val= val.toString().match(/^([+-]=)?([\d+-.]+)(.*)$/);
	if(!val[3]){val[3]='px'}
	return val;
}

js.count= function(obj,fn){
	var isFn= js.isFunction(fn);
	var count= 0;
	var prop;
	for(prop in obj){
		if(isFn){
			if(fn.call(obj[prop])===false){continue}
		}
		count++;
	}
	return count;
}

js.toInt= function(){
	var val= arguments.length ? arguments[0] : this;
	return parseInt(val,10) || 0;
}

js.toFloat= function(){
	var val= arguments.length ? arguments[0] : this;
	return parseFloat(val) || 0;
}

//reserv
js.toHex= function(){
	var val= arguments.length ? arguments[0] : this;
	return val
},

// reserv
js.toDec= function(){
	var val= arguments.length ? arguments[0] : this;
	return val
}

/* @param:
 * function, (1st is onReady, 2nd - onNotReady)
 * state (only for Elements and not for document!):
 * 		onLoad 	 	- HTML is only loaded and not rendered
 * 		onRender	- HTML is loaded and rendered (width || height > 0), wait 10 sec. (Default)
 * 		onDisplay 	- HTML is rendered and visible, wait to visible, same as onRender but w/o timeout
 */
js.onReady= function(){
	var self= this;
	var fn= null;
	var onNotReady= null;
	var state;
	for(var i=0; i<arguments.length&&i<3;i++){
		if(typeof arguments[i] == 'function'){
			if(i==2 && typeof arguments[i] == 'function'){
				onNotReady = arguments[i];
			}else{
				fn= arguments[i];
			}
		}
		else{state= arguments[i];}
	}
	if(state && state.length){state= state.toLowerCase();}
	else{state= 'onrender';} // default}
	if(!fn){return false;}

	var timeout= 10; // sec.
	var delay= 5; // delay in ms
	var count= timeout * ((0|1e3/delay) || 1);// count of steps
	var tReady= 0;

	var isRendered= false;
	if(state == 'onload'){isRendered= false;}
	else if(state == 'onrender'){isRendered= true;}
	else if(state == 'ondisplay'){timeout= 0;isRendered= true;}

	// for document
	// IE6/7, FF3.5, Opera 9.2, Chrome 3, Safari 3, SeaMonkay 1.1
	// wait on load all DOM Elements, but not on full load of Images! it is ready!
	var body= null;
	var nodesLen= 0;
	var isDocumentReady= function(){
		body= document.getElementsByTagName("body")[0];
		if(!body){
			return false;
		}
		var curNodesLen= body.childNodes.length;
		if(curNodesLen > nodesLen){
			nodesLen= curNodesLen;
			return false;
		}
		return true;
	}
	// for DOM Elements
	var isElementReady= function(){
		self.elm= document.getElementById(self.id) || document.querySelector(self.id);
		return ( (isRendered && self.elm && self.elm.offsetHeight) || (!isRendered && self.elm) );
	}
	var isReady;

	if(self.elm && self.elm.id === 'document'){
		isReady= isDocumentReady;
	}else{isReady= isElementReady}

	function _loop(){
		if(isReady()){
			clearInterval(tReady);tReady= 0;
			fn.call(self,self.elm);
			return;
		}else if(timeout){
			if(count < 1){
				clearInterval(tReady);
				if(onNotReady){
					onNotReady.call(self,self.elm);
				}else{
					//alert('SYSERROR: Timeout > '+timeout+' sec.\nElement "#'+self.id+'" not found!');
					console.log('\nSYSERROR: Timeout > '+timeout+' sec.\nElement "#'+self.id+'" not found!\n--> Reload the page!');
				}
				return;
			}
			count--;
		}
		if(!tReady){tReady= setInterval(function(){_loop()},delay);}
	}// wait
	_loop();
}// onReady

// js('inp_btn','name').each(function(params){alert(params)},[params]);
/*
js.each= function(){
	var self= this;
	var elms;
	var iFn;
	if(!arguments.length){
		return;
	}else
	if(arguments.length > 1){
		elms= arguments[0];
		iFn= 1;
	}else{
		elms= this;
		iFn= 0;
	}

	var elm_type= js.typeOf(elms);
	if(elm_type == 'array'){
		for(var i=0;i<elms.length;i++){
			arguments[iFn].call(elms[i],arguments[2]);
		}
	}else
	if(elm_type = 'object'){;
		if(elms.elm){
			elms= elms.elm;
		}
		for(var elm in elms){alert(elm)
			arguments[iFn].call(elm,arguments[2]);
		}
	}else{
		arguments[iFn].call(elms,arguments[2]);
	}

}*/

//js.each({key:'val'},function(key,val){})
js.each= function(){
	var elms, iFn;
	if(!arguments.length){return}

	if(arguments.length > 1){// hier kann nur 1 elm oder object oder array sein
		elms= arguments[0];
		iFn= 1;
	}else{// hier kann nur 1 elm oder array sein
		elms= this;
		iFn= 0;
	}

	if(js.isObject(elms)){;
		if(elms.elm){
			elms= elms.elm;
		}
		for(var key in elms){
			var val= elms[key];
			arguments[iFn].call(this,key,val);
		}
	}
}

// TODO each, s: goog.forEach
/*js.each = function (arr, fn, oOpt) {
	var l = arr.length;
	var arr2 = js.isString(arr) ? arr.split('') : arr;
	var i;
	for (i = 0; i < l; i++) {
		if (i in arr2) {
			fn.call(oOpt, arr2[i], i, arr);
		}
	}
}*/


//::: MISC :::

js.redirect= function(url){
	if(url.indexOf('?') < 0){url+= '?';}
	window.location.replace(url+'&'+js.uniqId());
}//


/**
 * return milliseconds
 * profiled+
 */
js.msec= function(){
	return (new Date).getTime();
}

/**
 * return timestamp
 * profiled+
 */
js.tstamp= function(){
	return 0|js.msec()/1e3;
}

/**
 * Generate a unique ID
 * return integer Id
 * profiled+
 */
js.uniqId= function(){
    var id= 1;
    return 0|Math.random()*1e6;
};

/*
js.uniqId1= (function(){
    var id= 1;
    return function(){return id++};
})();
*/

// JS Extensions

// ### Function ###

// js.event.add(elm, type, function(){alert(this.href)}.bind(elm));
if(!Function.prototype.bind){
	Function.prototype.bind= function(obj){
		var method = this;
		return function(){return method.apply(obj, arguments)}
	}
}

// ### String ###

// trim
if (String.prototype.trim === undefined) {
	String.prototype.trim= function(){return this.replace(/^\s+/, '').replace(/\s+$/, '')}
}

//ftrim - fullTrim: left + inner + right
if (String.prototype.ftrim === undefined) {
	String.prototype.ftrim= function(){return this.replace(/^\s+/, '').replace(/\s{2,}/, ' ').replace(/\s+$/, '')}
}

//itrim - innerTrim, " ABC  QWE ERT  " =>  " ABC QWE ERT "
if (String.prototype.itrim === undefined) {
	String.prototype.itrim= function(){return this.replace(/\s{2,}/, ' ')}
}

// ltrim
if (String.prototype.ltrim === undefined) {
	String.prototype.ltrim= function(){return this.replace(/^\s+/, '')}
}

// rtrim
if (String.prototype.rtrim === undefined) {
	String.prototype.rtrim= function(){return this.replace(/\s+$/, '')}
}

// ### Array ###

// indexOf - find index of value in array
if(!Array.prototype.indexOf){
	Array.prototype.indexOf= function(value, offset){
		for(var i= offset||0, length= this.length; i<length; i++){
			if(this[i] === value) return i;
		}
		return -1
	};
}

// ### Object ###
/*if(!Object.prototype.count){
	//Object.DontEnum= {bind:1,count:1}; // function die nicht mit gezÃ¤ht werden mussen
	Object.prototype.count= function(){
		if(!this.hasOwnProperty){return false}
		var count= 0;
		var prop;
		for(prop in this)if(prop != 'count'){count++} // only in {} ignore self
		//var DontEnum= Object.DontEnum;
		//for(prop in this)if(!DontEnum[prop]){count++} // DontEnum ignore in Function and in Object
		return count;
	}
}*/




//indexOf - find index of value in object
/*Object.prototype.indexOf=function(value){
	var index;
	for(index in this){
		if(index == 'indexOf'){continue}
		if(this[index] === value){
			 return index;
		}
	}
	return -1;
}*/

/**
 * Optimisations
*/

// round to integer: 0|x; quickly as Math.floor, -5.99 => -5; 5.1 => 5
// 10000: 1e4; shoter as 1000, speed is same

export default js;