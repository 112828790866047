import Base64 from '../core/Base64';
import point from './Point';
import curve from './Curve';

var customParam = {
    isUsed: false,

    keyMapp: {
        'sharpening': 'shp',
        'saturation': 'sat',
        'hue': 'hue',
        'minOutput': 'mino',
        'maxOutput': 'maxo',
        'minInput': 'mini',
        'maxInput': 'maxi',
        'gamma': 'g'
    }
}

customParam.init = function () {
    js('btnMakeUrl').addEvent('click', customParam.makeUrl);
}

customParam.makeUrl = function () {
    var param = {
        'urlImage': js('inpImageUrl').elm.value
    };

    var key, id;
    for (id in customParam.keyMapp) {
        key = customParam.keyMapp[id];
        param[key] = js(id).elm.value;
    }

    param.res = 480; // default preview size
    if (npc.res > 100) {
        param.res = npc.res;
    }

    var points = npc.data.splain.points;
    var i;
    for (i = 0; i < points.length; i++) {
        param['cpi_' + i] = points[i][0];
        param['cpo_' + i] = points[i][1];
    }

    var strParam = '';
    for (key in param) {
        if (strParam) {
            strParam += '&';
        }
        strParam += key + '=' + param[key];
    }

    // show panels
    var mapPanel = {
        'viewHelp': 'hh',
        'viewCustomPreview': 'hvcp',
        'viewPcList': 'hpcl',
        'viewPcSettings': 'hpcs',
        'viewSourceImage': 'hsi',
        'disqus_thread': 'hdc',
        'viewAdSense': 'ads'
    };
    $('.fn-view-panel').each(function (idx, elm) {
        if (!elm.checked) {
            if (mapPanel[elm.name] !== undefined) {
                strParam += '&' + mapPanel[elm.name];
            }
        }
    });

    // add selected npc name to share params
    var elmFileList = js('selFileList').elm,
      idx = elmFileList.selectedIndex,
      filename = elmFileList.options[idx].value

    //console.log('strParam: ', filename);
    //var npcName = $('.lbjs-item[selected]')[0].innerText;
    strParam += '&filename=' + filename + '&';

    strParam = Base64.encode(strParam);

    var url = window.location;
    var customUrl = url.protocol + '//' + url.hostname + '/?p=' + strParam;
    js('viewCustomUrl').elm.value = customUrl;
    $('#viewCustomUrl').select();
    document.execCommand('copy');
}

customParam.getParams = function () {
    if (customParam.isUsed) {
        return;
    }

    var params = customParam.getParamsFromUrl();
    if (!params) {
        return false;
    }

    //console.log('customParam: ', params);

    point.removeAll();

    // set params in form und update
    var keyMapp = customParam.keyMapp,
      keyInp,
      keyOut;

    // curve points
    var i, key;
    for (i = 0; i < 20; i++) {
        keyInp = 'cpi_' + i;
        keyOut = 'cpo_' + i;
        //alert(params[keyInp]);
        if (params[keyInp] === undefined) {
            break;
        }
        keyMapp['inpPointX_' + i] = keyInp;
        keyMapp['inpPointY_' + i] = keyOut;
    }

    var id;
    for (id in keyMapp) {
        key = keyMapp[id];
        if (params[key] === undefined) {
            continue;
        }
        js(id).elm.value = params[key];

        if (npc.data[id] !== undefined) {
            npc.data[id] = params[key];
        }
    }

    if (parseInt(params.res, 10) > 200) {
        npc.res = params.res;
    }

    point.updateValues(1); // update curve
    curve.setAdjust(); // update adjust
    customParam.isUsed = true;

    return params;
}

customParam.getParamsFromUrl = function () {
    var strParam = window.location.search.substr(1);
    var res = customParam.getParamFromStr(strParam);
    if (!res.p) {
        return null;
    }

    var params = Base64.decode(res.p);
    return customParam.getParamFromStr(params);
}

customParam.getParamFromStr = function (str) {
    str = decodeURIComponent(str);

    var response = {};
    var keyVal = str.split('&');
    var param, i;

    for (i = 0; i < keyVal.length; i++) {
        if (!keyVal[i]) {
            continue;
        }
        param = keyVal[i].split('=');
        response[param[0]] = param[1];
    }

    return response;
}

customParam.setParams = function () {
    var res = customParam.getParams();

    return !!res;
}

js('btnMakeUrl').onReady(function () {
    customParam.init();
});

export default customParam;