/**
 * UTF-8 encode / decode.
 */

var Utf8 = {};

/**
 * Encode string to utf-8.
 *
 * @param {string} input
 *
 * @return {string}
 */
Utf8.encode = function (input) {
  var len = input.length,
    output = '',
    code,
    i;

  input = input.replace(/\r\n/g, "\n");
  for (i = 0; i < len; i++) {
    code = input.charCodeAt(i);
    if (code < 128) {
      output += String.fromCharCode(code);
    } else if ((code > 127) && (code < 2048)) {
      output += String.fromCharCode((code >> 6) | 192);
      output += String.fromCharCode((code & 63) | 128);
    } else {
      output += String.fromCharCode((code >> 12) | 224);
      output += String.fromCharCode(((code >> 6) & 63) | 128);
      output += String.fromCharCode((code & 63) | 128);
    }
  }

  return output;
};

/**
 * Decode utf-8 string.
 *
 * @param {string} input
 *
 * @return {string}
 */
Utf8.decode = function (input) {
  var len = input.length,
    output = '',
    i = 0,
    code;

  while (i < len) {
    code = input.charCodeAt(i++);
    if (code < 128) {
      output += String.fromCharCode(code);
    } else if ((code > 191) && (code < 224)) {
      output += String.fromCharCode(((code & 31) << 6) | (input.charCodeAt(i++) & 63));
    } else {
      output += String.fromCharCode(((code & 15) << 12) | ((input.charCodeAt(i++) & 63) << 6) | (input.charCodeAt(i++) & 63));
    }
  }

  return output;
};

export default Utf8;