/**
 * nikon picture control sharping
 *
 * @param {Number} sharp
 * @param data
 * @param width
 * @param height
 */
var sharp = function (sharp, data, width, height) {
//var sharp = function(sharp, imgData, width, height){

    if (sharp < 0) sharp = 0;
    if (sharp > 0.5) sharp = 0.5;

    // copy data to buf
    /*var data = [];
     var size = imgData.length;
     var i;
     for(i = 0; i < size; i++){
     data[i] = imgData[i];
     }*/

    //
    var imgData = [];
    var size = data.length;
    var i;
    /*for(i = 0; i < size; i++){
     imgData[i] = data[i];
     }*/

    var mul = 15;
    var mulOther = 1 + 3 * sharp;

    var kernel = [
        [0, -mulOther, 0],
        [-mulOther, mul, -mulOther],
        [0, -mulOther, 0]
    ];

    var weight = 0;
    var i, j;
    for (i = 0; i < 3; i++) {
        for (j = 0; j < 3; j++) {
            weight += kernel[i][j];
        }
    }

    weight = 1 / weight;

    var w = width;
    var h = height;

    mul *= weight;
    mulOther *= weight;

    var w4 = w * 4;
    var y = h;

    do {
        var offsetY = (y - 1) * w4;

        var nextY = (y == h) ? y - 1 : y;
        var prevY = (y == 1) ? 0 : y - 2;

        var offsetYPrev = prevY * w4;
        var offsetYNext = nextY * w4;

        var x = w;
        do {
            var offset = offsetY + (x * 4 - 4);

            var offsetPrev = offsetYPrev + ((x == 1) ? 0 : x - 2) * 4;
            var offsetNext = offsetYNext + ((x == w) ? x - 1 : x) * 4;

            imgData[offset] = data[offset];
            imgData[offset + 1] = data[offset + 1];
            imgData[offset + 2] = data[offset + 2];

            imgData[offset - 2] = data[offset - 2];
            imgData[offset - 3] = data[offset - 3];
            imgData[offset - 4] = data[offset - 4];
            imgData[offset + 4] = data[offset + 4];
            imgData[offset + 5] = data[offset + 5];
            imgData[offset + 6] = data[offset + 6];

            imgData[offsetPrev] = data[offsetPrev];
            imgData[offsetPrev + 1] = data[offsetPrev + 1];
            imgData[offsetPrev + 2] = data[offsetPrev + 2];

            imgData[offsetNext] = data[offsetNext];
            imgData[offsetNext + 1] = data[offsetNext + 1];
            imgData[offsetNext + 2] = data[offsetNext + 2];

            var r = ((
                -imgData[offsetPrev]
                - imgData[offset - 4]
                - imgData[offset + 4]
                - imgData[offsetNext]) * mulOther
                + imgData[offset] * mul
            );

            var g = ((
                -imgData[offsetPrev + 1]
                - imgData[offset - 3]
                - imgData[offset + 5]
                - imgData[offsetNext + 1]) * mulOther
                + imgData[offset + 1] * mul
            );

            var b = ((
                -imgData[offsetPrev + 2]
                - imgData[offset - 2]
                - imgData[offset + 6]
                - imgData[offsetNext + 2]) * mulOther
                + imgData[offset + 2] * mul
            );


            if (r < 0) r = 0;
            if (g < 0) g = 0;
            if (b < 0) b = 0;
            if (r > 255) r = 255;
            if (g > 255) g = 255;
            if (b > 255) b = 255;

            data[offset] = r;
            data[offset + 1] = g;
            data[offset + 2] = b;

        } while (--x);
    } while (--y);

    return data;
}

export default sharp;