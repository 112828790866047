import curve from './Curve';
import lut from './Lut';

var point = {
    wSel: 10,
    hSel: 10,
    _selOffsetX: 0,
    _selOffsetY: 0,
    selPoint: 0, // 0 - not selected, > 0 - selected point number
    wasChanged: false,
    keyCode: 0 // pressed key
}

point.init = function () {
    /*js('selectedPoint').addEvent('click', function(){
     js('pointKey').elm.focus();
     });*/

    point._selOffsetX = Math.floor(point.wSel / 2);
    point._selOffsetY = Math.floor(point.hSel / 2);

    point.initKeySelector();
    point.initValues();
    point.initNewPoint();
}

point.initValues = function () {
    $('.point-container').each(function (idx, elm) {
        var num = parseInt(elm.id.replace('point_', ''), 10);

        js('num_' + num).addEvent('click', function (e) {
            point.select(num);
            js('pointKey').elm.focus();

            return false;
        });

        js('inpPointX_' + (num - 1)).addEvent('keyup', function (e) {
            if (e.which == js.event.keyCode.ENTER) {
                point.updateValues(1);
                js('pointKey').elm.focus();
            }
        });

        js('inpPointY_' + (num - 1)).addEvent('keyup', function (e) {
            if (e.which == js.event.keyCode.ENTER) {
                point.updateValues(1);
                js('pointKey').elm.focus();
            }
        });

    });

    //
    js('gamma').addEvent('keyup', function (e) {
        if (e.which == js.event.keyCode.ENTER) {
            var val = parseFloat(this.value);
            if (val > 10) {
                val = 9
            } else if (val < 0.1) {
                val = 0.1;
            }
            npc.cache[npc.cacheKey].splain.gamma = this.value = val;
            lut.$gamma = val;

            curve.draw(npc.cache[npc.cacheKey]);
            curve.setAdjust();
            js('gamma').elm.focus();
        }
    });

    js('minInput').addEvent('keyup', function (e) {
        if (e.which == js.event.keyCode.ENTER) {
            var val = parseInt(this.value);
            if (val > 255) {
                val = 255
            } else if (val < 0) {
                val = 0;
            }
            npc.cache[npc.cacheKey].splain.minInput = this.value = val;
            curve.draw(npc.cache[npc.cacheKey]);
            curve.setAdjust();
            js('minInput').elm.focus();
        }
    });

    js('maxInput').addEvent('keyup', function (e) {
        if (e.which == js.event.keyCode.ENTER) {
            var val = parseInt(this.value);
            if (val > 255) {
                val = 255
            } else if (val < 0) {
                val = 0;
            }
            npc.cache[npc.cacheKey].splain.maxInput = this.value = val;
            curve.draw(npc.cache[npc.cacheKey]);
            curve.setAdjust();
            js('maxInput').elm.focus();
        }
    });

    js('minOutput').addEvent('keyup', function (e) {
        if (e.which == js.event.keyCode.ENTER) {
            var val = parseInt(this.value);
            if (val > 255) {
                val = 255
            } else if (val < 0) {
                val = 0;
            }
            npc.cache[npc.cacheKey].splain.minOutput = this.value = val;
            curve.draw(npc.cache[npc.cacheKey]);
            curve.setAdjust();
            js('minOutput').elm.focus();
        }
    });

    js('maxOutput').addEvent('keyup', function (e) {
        if (e.which == js.event.keyCode.ENTER) {
            var val = parseInt(this.value);
            if (val > 255) {
                val = 255
            } else if (val < 0) {
                val = 0;
            }
            npc.cache[npc.cacheKey].splain.maxOutput = this.value = val;
            curve.draw(npc.cache[npc.cacheKey]);
            curve.setAdjust();
            js('maxOutput').elm.focus();
        }
    });

    // adjusts
    js('sharpening').addEvent('keyup', function (e) {
        if (e.which == js.event.keyCode.ENTER) {
            var val = parseInt(this.value, 10);
            if (val > 9) {
                val = 9
            } else if (val < 0) {
                val = 0;
            }
            npc.data.sharpening = this.value = val;
            curve.draw(npc.cache[npc.cacheKey]);
            curve.setAdjust();
            js('pointKey').elm.focus();
        }
    });

    js('saturation').addEvent('keyup', function (e) {
        if (e.which == js.event.keyCode.ENTER) {
            var val = parseInt(this.value, 10);
            if (val > 3) {
                val = 3
            } else if (val < -3) {
                val = -3;
            }
            npc.data.saturation = this.value = val;
            curve.draw(npc.cache[npc.cacheKey]);
            curve.setAdjust();
            js('pointKey').elm.focus();
        }
    });

    js('hue').addEvent('keyup', function (e) {
        if (e.which == js.event.keyCode.ENTER) {
            var val = parseInt(this.value, 10);
            if (val > 3) {
                val = 3
            } else if (val < -3) {
                val = -3;
            }
            npc.data.hue = this.value = val;
            curve.draw(npc.cache[npc.cacheKey]);
            curve.setAdjust();
            js('pointKey').elm.focus();
        }
    });

    // check download fields
    js('fileName').addEvent('keyup', function (e) {
        var val = this.value;

        val = val.split('.', 1).toString();

        val = val.replace(/[^0-9]/g, '');
        if (val.length > 2) {
            val = val.substr(0, 2);
        }
        this.value = val;
    });

    js('RegistrationName').addEvent('keyup', function (e) {
        var val = this.value;
        var len = 19;

        val = val.replace(/[^A-Za-z0-9 \[\]_{}!"#$%&\'()\*\+,-\.\/:;<=>\?@]/g, '');
        this.value = val.substr(0, len);
    });

}

point.initSelectPoints = function () {
    var points = npc.data.splain.lutPoints; // 100% OK!
    var amount = points.length;
    var gamma = npc.data.splain.gamma;
    var x, y, i;

    js('selectedPointContainer').removeChilds(); // remove old point

    //console.log('points: ', points);

    for (i = 0; i < amount; i++) {
        x = Math.round(points[i][0]);
        y = curve.lutCurve.curve[x];
        y = 255 - y;

        x -= (point.wSel / 2);
        y -= (point.hSel / 2);

        var sel = js('selectedPointContainer').addLast('<div id="selectedPoint_' + (i + 1) + '" class="selected-point" style="left:' + x + 'px;top:' + y + 'px;"> </div>');
        sel.css({'opacity': 0});

        point.initMouseSelector(sel.elm);
    }
}

/**
 * initialize of create new point on curve (CTRL + mouse)
 * max. 20 points
 *
 * @type Object
 */
point.initNewPoint = function () {
    js('pointKey').elm.focus(); // set focus for listen of keys

    /*js(document).addEvent('click', function(e){
     alert(2);
     js('pointKey').elm.focus();
     });*/

    js('selectedPointContainer').addEvent('click', function (e) {
        js('pointKey').elm.focus();

        if (npc.data.splain.lutPoints.length >= 20) {
            return false;
        }

        if (point.keyCode === js.event.keyCode.CONTROL || point.keyCode === js.event.keyCode.MAC_COMMAND) {
            var pos = $(this).offset();
            var x = e.posX - pos.left;
            var y = 255 - e.posY + Math.round(pos.top);

            // gamma calc
            var g = parseFloat(npc.data.splain.gamma);
            y -= lut.gamma(x);

            js('inpPointX_' + 19).elm.value = x;
            js('inpPointY_' + 19).elm.value = y;

            point.updateValues(1);
        }
    });

}

/**
 * remove a point
 *
 * @type Object
 */
point.remove = function () {
    if (!point.selPoint) {
        return false;
    }
    var idx = point.selPoint - 1;
    js('inpPointX_' + idx).elm.value = '';
    js('inpPointY_' + idx).elm.value = '';

    npc.data.splain.points.pop(); // remove last element
    npc.data.splain.lutPoints.pop(); // remove last element

    point.updateValues(1);
}

// TODO: by move calc min/max In/Output
point.initMouseSelector = function (elm) {

    js(elm).drag({
        axis: 'xy',
        onStart: function (e, pos) {
            var elm = this;
            var num = elm.id.replace('selectedPoint_', '');
            point.select(num);
        },
        onMove: function (e, pos) {
            var elm = this,
                stop = false;
            curve.wasChanged = true;

            // TODO: points limit (abhängig vom gamma)
            /*if(pos.x < 0 || pos.y < 0){
             return -1;
             }*/

            // set values
            var valIn = pos.x + point._selOffsetX;
            var valOut = 255 - pos.y - point._selOffsetY;

            if (lut.$gamma !== 1) {
                valOut -= lut.gamma(valIn); // 100% fertig!
            }

            if (valIn < 0 || valOut < 0 || valIn > 255 || valOut > 255) {
                // stop moving
                return -1;
            }


            point.setValues(valIn, valOut);
            point.updateValues();
        },

        onStop: function (e, pos) {
            var elm = this;
            var x, y;
            if (curve.wasChanged) {
                curve.setAdjust();
            }
            js('pointKey').elm.focus();
        }
    });
}

point.initKeySelector = function () {
    var elm, idx, x, y;
    js('pointKey').addEvent('keydown', function (e) {
        point.keyCode = e.which;

        if (!point.selPoint) {
            return false;
        }

        var useGamma = (lut.$gamma != 1) ? 1 : 0;

        elm = js.obj['selectedPoint_' + point.selPoint].elm;
        x = parseInt(js(elm).css('left'), 10);
        y = parseInt(js(elm).css('top'), 10);
        idx = point.selPoint - 1;

        // for view Lut points
        var lutPointIn = npc.data.splain.lutPoints[idx][0]; // lutPoints 100% ok
        var lutPointOut = npc.data.splain.lutPoints[idx][1];

        // for lut curve
        var pointIn = npc.data.splain.points[idx][0]; // points 100% ok
        var pointOut = npc.data.splain.points[idx][1];

        switch (e.which) {
            case js.event.keyCode.LEFT:
                if (pointIn > 0) {
                    x--;
                    lutPointIn--;
                    pointIn--;
                }
                curve.wasChanged = true;
                break;
            case js.event.keyCode.RIGHT:
                if (pointIn < 255) {
                    x++;
                    lutPointIn++;
                    pointIn++;
                }
                curve.wasChanged = true;
                break;
            case js.event.keyCode.UP:
                if (pointOut < 255) {
                    y--;
                    lutPointOut++; // inverted
                    pointOut++; // inverted
                }
                curve.wasChanged = true;
                break;
            case js.event.keyCode.DOWN:
                if (pointOut > 0) {
                    y++;
                    lutPointOut--; // inverted
                    pointOut--; // inverted
                }
                curve.wasChanged = true;
                break;
            case js.event.keyCode.BACKSPACE:
            case js.event.keyCode.DELETE:
                point.remove();
                return false;
                break;
        }

        if (curve.wasChanged) {
            point.setValues(lutPointIn, lutPointOut);

            //point.updateValues(); // zu grosse error 0.xxxx
            npc.data.splain.points[idx] = [pointIn, pointOut];
            npc.data.splain.lutPoints[idx] = [lutPointIn, lutPointOut];
            curve.draw(npc.data);

            x = Math.round(npc.data.splain.lutPoints[idx][0]); // lutPoints 100% ok, round ist wichtig!
            y = 255 - curve.lutCurve.curve[x] - point._selOffsetY;
            x -= point._selOffsetX;

            js(elm).css({'left': x + 'px', 'top': y + 'px'})
        }

        return false;
    });

    js('pointKey').addEvent('keyup', function (e) {
        point.keyCode = 0;
        if (curve.wasChanged) {
            curve.setAdjust();
        }
    });
}

point.setValues = function (valIn, valOut) {
    if (!point.selPoint) {
        return;
    }

    var idx = point.selPoint - 1;
    js.obj['inpPointX_' + idx].elm.value = Math.round(valIn);
    js.obj['inpPointY_' + idx].elm.value = Math.round(valOut);
}

point.select = function (num) {
    if (point.selPoint) {
        js('point_' + point.selPoint).removeClass('point-container-selected');
        if (js('selectedPoint_' + point.selPoint).elm) {
            js('selectedPoint_' + point.selPoint).css({'opacity': 0});
        }
    }

    if (num > npc.data.splain.lutPoints.length) {
        point.selPoint = null;
        return false;
    }

    point.selPoint = num;
    js('point_' + point.selPoint).removeClass('point-container-selected', 'point-container-selected');

    var elmSelectedPoint = js('selectedPoint_' + num);
    if (elmSelectedPoint.elm) {
        elmSelectedPoint.css({'opacity': 100});
    }

    //js('pointKey').elm.focus();

    return true;
}

point.updateValues = function (updateValues) {
    var i, x, y;

    var lutData = npc.data.splain;
    var diffIn = lutData.maxInput - lutData.minInput;
    var diffOut = lutData.maxOutput - lutData.minOutput;
    //$('#debug')[0].innerHTML = '';

    // decode points from curve points to lut points
    // TODO: reduzieren calc fehler nach koma: 0.xxxxx
    var idx = 0;
    for (i = 0; i < 20; i++) {

        x = parseInt(js('inpPointX_' + i).elm.value, 10);
        y = parseInt(js('inpPointY_' + i).elm.value, 10);
        if (x >= 0 && y >= 0) {

            var inp = Math.round((x - lutData.minInput) * 255 / diffIn);
            var out = Math.round((y - lutData.minOutput) * 255 / diffOut);

            if (inp < 0) {
                inp = 0;
            }
            if (out < 0) {
                out = 0;
            }

            // TODO: inp, out calc ohne round
            npc.data.splain.points[idx] = [inp, out];
            npc.data.splain.lutPoints[idx] = [x, y];

            //debug
            //$('#debug')[0].innerHTML += inp +' : '+ out +"<br>";
            //###
            idx++;
        } else {
            delete(npc.data.splain.points[i]);
            delete(npc.data.splain.lutPoints[i]);
        }
    }

    if (updateValues) {
        point.sort();
        point.viewValues();
    }

    curve.draw(npc.data); // draw curve, histogram, custom image without adjusts

    if (updateValues) {
        point.initSelectPoints(); // muss nach dem curve.draw sein
    }
}

point.removeAll = function () {
    var idx = 0;
    for (var i = 0; i < 20; i++) {
        js('inpPointX_' + i).elm.value = '';
        js('inpPointY_' + i).elm.value = '';
        //delete(npc.data.splain.points[i]);
        //delete(npc.data.splain.lutPoints[i]);
    }
    npc.data.splain.points = [];
    npc.data.splain.lutPoints = [];
}

point.sort = function () {
    var equp = function (a, b) {
        if (a[0] < b[0]) {
            return -1;
        }

        if (a[0] > b[0]) {
            return 1;
        }

        return 0;
    }

    npc.data.splain.points = npc.data.splain.points.sort(equp);
    npc.data.splain.lutPoints = npc.data.splain.lutPoints.sort(equp);
}

point.viewValues = function () {
    var i, val;
    var elmX, elmY;
    for (i = 0; i < 20; i++) {
        val = npc.data.splain.lutPoints[i];
        elmX = js('inpPointX_' + i).elm;
        elmY = js('inpPointY_' + i).elm;

        if (val) {
            elmX.value = val[0];
            elmY.value = val[1];
        } else if (elmX.value && elmY.value) {
            elmX.value = '';
            elmY.value = '';
        }
    }
}

point.calcPointsByInOut = function ($lutData) {
    var $response = [];

    var $minIn = $lutData['minInput'];
    var $maxIn = $lutData['maxInput'];
    var $minOut = $lutData['minOutput'];
    var $maxOut = $lutData['maxOutput'];

    var $diffIn = $maxIn - $minIn;
    var $diffOut = $maxOut - $minOut;

    var $amount = $lutData['points'].lengh;
    for (var $i = 0; $i < $amount; $i++) {
        var $in = $lutData['points'][$i][0];
        var $out = $lutData['points'][$i][1];

        $in = $diffIn * $in / 255 + $minIn;
        $out = $diffOut * $out / 255 + $minOut;

        $response[$i] = [$in, $out];
    }

    return $response;
}


js('selectedPoint_1').onReady('onload', function () {
    var elm = js('pointKey').elm;
    //elm.href = '#';

    point.init();
});

export default point;
