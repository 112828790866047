import Utf8 from './Utf8'

const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

var Base64 = {};

/**
 * Encode string to base64.
 *
 * @param {string} input
 *
 * @return {string}
 */
Base64.encode = function (input) {
    var output = '',
      i = 0,
      chr1, chr2, chr3,
      enc1, enc2, enc3, enc4,
      len;

    input = Utf8.encode(input);
    len = input.length;

    while (i < len) {
        chr1 = input.charCodeAt(i++);
        chr2 = input.charCodeAt(i++);
        chr3 = input.charCodeAt(i++);

        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;

        if (isNaN(chr2)) {
            enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
            enc4 = 64;
        }

        output +=
          charset.charAt(enc1) + charset.charAt(enc2) +
          charset.charAt(enc3) + charset.charAt(enc4);
    }

    return output;
};

/**
 * Decode base64 string.
 *
 * @param {string} input
 *
 * @return {string}
 */
Base64.decode = function (input) {
    var output = '',
      i = 0,
      chr1, chr2, chr3,
      enc1, enc2, enc3, enc4,
      len;

    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');
    len = input.length;

    while (i < len) {
        enc1 = charset.indexOf(input.charAt(i++));
        enc2 = charset.indexOf(input.charAt(i++));
        enc3 = charset.indexOf(input.charAt(i++));
        enc4 = charset.indexOf(input.charAt(i++));

        chr1 = (enc1 << 2) | (enc2 >> 4);
        chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
        chr3 = ((enc3 & 3) << 6) | enc4;

        output += String.fromCharCode(chr1);

        enc3 !== 64 && (output += String.fromCharCode(chr2));
        enc4 !== 64 && (output += String.fromCharCode(chr3));
    }

    return Utf8.decode(output);
};

export default Base64;